import { ref } from '@vue/composition-api'

export default function addProduct() {
  const productAdd = ref({
    title: '',
    slug: '',
    speakers: [],
    category_ids:[],
  })

  const imagesId = []
  const images = []

  const arrayRegion = [
    {
      region_id: '',
      from_price: '',
    },
  ]
  const arrayDescription = [
    {
      region_id: '',
      title: '',
      description: '',
    },
  ]
  const regionId = ref(null)
  const countDescription = ref(1)
  const countRegion = ref(1)
  const from_price = ref(null)
  const toPrice = ref(null)
  const updateItemFormDescription = (index, val) => {
    const region_id = val
    arrayDescription[index].region_id = region_id
  }
  const updateItemFormRegion = (index, val) => {
    const region_id = val
    arrayRegion[index].region_id = region_id
  }
  return {
    productAdd,
    images,
    from_price,
    toPrice,
    arrayDescription,
    arrayRegion,
    countDescription,
    countRegion,
    updateItemFormRegion,
    updateItemFormDescription,
    imagesId,
    regionId,
  }
}
